/**
 * This is a copy of the search route just so our cloudflare worker can hit it
 * @see https://triadms.atlassian.net/wiki/spaces/NEWPLATFOR/pages/2137391105/Click+Portal+Search+Results+By+State
 */
import ClickPortalSearch, { getServerSideProps } from './search';

export default ClickPortalSearch;

export { getServerSideProps };

/**
 * Flows
 * - User comes in on /search -> worker requests to to /my-search -> cached by headers
 * - User updates Form and API is called -> if cookie is on browser we will tell proxy endpoint to include the geoLocation
 * - User client side navigation -> if cookie on browser cloudflare cache key kicks in on this -> calls getServerSideProps like an endpoint
 */
